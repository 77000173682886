<template>
  <div>
    <v-btn color="info" class="w-100 mt-5" @click="handleGetAgendamentos">
      <v-icon class="mr-2" left dark> mdi-download </v-icon>
      Baixar Planilha</v-btn
    >
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ExtracaoApi from '@/services/extracao/index'
export default {
  name: 'CargaDescargaListConcluidos',
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('patio', ['agendamentos', 'regioes']),
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('extracaoCargaDescarga', ['params']),
    name() {
      const date = new Date()
      const data = `${`${date.getFullYear()}`.padStart(4, 0)}-${`${
        date.getMonth() + 1
      }`.padStart(2, 0)}-${`${date.getDate()}`.padStart(
        2,
        0
      )}T${`${date.getHours()}`.padStart(
        2,
        0
      )}:${`${date.getMinutes()}`.padStart(
        2,
        0
      )}:${`${date.getSeconds()}`.padStart(
        2,
        0
      )}.${`${date.getMilliseconds()}`.padStart(3, 0)}`
      return `exportacao_carga-descarga_${data}`
    },
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async handleGetAgendamentos() {
      if (!Object.keys(this.params).length) {
        this.errorMessage('Nenhum parâmetro selecionado!')
        return
      }
      this.$emit('loading')
      try {
        const data = await ExtracaoApi.getAgendamentoExportCsv(this.params)
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.name + '.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
      this.$emit('loaded')
    },
  },
}
</script>
