export const data = function () {
  return {
    dialogIndicadores: false,
    page: {
      title: 'Extração de Carga/Descarga',
    },
    headers: [
      {
        habilited: true,
        block: true,
        text: 'Ações',
        value: 'actions',
        sortable: false,
      },
      { habilited: true, block: true, text: 'Status', value: 'status' },
      {
        habilited: true,
        block: false,
        text: 'Veículo',
        value: 'veiculo.placa_cavalo',
      },
      {
        habilited: false,
        block: false,
        text: 'ID Bunge',
        value: 'classifWebId',
      },
      {
        habilited: true,
        block: false,
        text: 'Senha',
        value: 'senhas',
      },
      {
        habilited: true,
        block: false,
        text: 'Tipo Operação',
        value: 'tipo_operacao.descricao',
      },
      {
        habilited: true,
        block: false,
        text: 'Agendado Em',
        value: 'agendado_em',
      },
      {
        habilited: true,
        block: false,
        text: 'Triado Em',
        value: 'triado_em',
      },
      {
        habilited: true,
        block: false,
        text: 'Cliente',
        value: 'cliente.business_name',
      },
      {
        habilited: true,
        block: false,
        text: 'SubProduto',
        value: 'pedidos_agendados.subProduto',
      },
      {
        habilited: true,
        block: false,
        text: 'Quantidade (KG)',
        value: 'pedidos_agendados.quantidade',
      },
      {
        habilited: true,
        block: false,
        text: 'Embalagem',
        value: 'pedidos_agendados.embalagem',
      },
      {
        habilited: true,
        block: false,
        text: 'Terminal',
        value: 'terminal.contratado.trade_name',
      },
      {
        habilited: true,
        block: false,
        text: 'Terminal Destino',
        value: 'terminal_destino.contratado.trade_name',
      },
      {
        habilited: true,
        block: false,
        text: 'Baixa automática',
        value: 'baixa_automatica',
      },
      {
        habilited: true,
        block: false,
        text: 'Região',
        value: 'regiao',
      },
      {
        habilited: true,
        block: false,
        text: 'Eixos',
        value: 'veiculo.modalidade.eixo',
      },
      {
        habilited: false,
        block: false,
        text: 'Motorista',
        value: 'motorista.user.cpf',
      },
      {
        habilited: false,
        block: false,
        text: 'Proprietário',
        value: 'proprietario.business_name',
      },
      {
        habilited: false,
        block: false,
        text: 'Transportadora',
        value: 'transportadora.business_name',
      },
      {
        habilited: false,
        block: false,
        text: 'Destinatário',
        value: 'destinatario.business_name',
      },
      {
        habilited: false,
        block: false,
        text: 'Nº Pedido',
        value: 'pedidos_agendados.numero_pedido',
      },
      {
        habilited: false,
        block: false,
        text: 'Nº Nota fiscal',
        value: 'nota_fiscal',
      },
      {
        habilited: false,
        block: false,
        text: 'Produto',
        value: 'pedidos_agendados.produto',
      },

      {
        habilited: false,
        block: false,
        text: 'Návio',
        value: 'navio.descricao',
      },
      {
        habilited: false,
        block: false,
        text: 'Tipo Frete',
        value: 'tipo_frete.descricao',
      },
      { habilited: false, block: false, text: 'DI', value: 'di' },

      { habilited: false, block: false, text: 'Píer', value: 'pier' },
      {
        habilited: false,
        block: false,
        text: 'Observação',
        value: 'observacao',
      },

    ],
    breadcrumbs: [
      {
        text: 'Extração de Dados',
        disabled: false,
      },
      {
        text: 'Carga/Descarga',
        disabled: false,
      },
    ],
  }
}
