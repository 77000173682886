<template>
  <v-container fluid>
    <BaseBreadcrumb
      :tela="permission"
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col>
        <carga-descarga-list-filtro @search="handleGetAgendamentos" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Relatório de Carga/Descarga">
          <template slot="right">
            <v-dialog v-model="dialogIndicadores" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canList"
                  color="primary"
                  dark
                  class="ml-2 mb-2"
                  v-on="on"
                  >Indicadores</v-btn
                >
              </template>
              <indicadores-gerencimento-servico
                :headers="headers"
                @close="closeDialogIndicadores"
              />
            </v-dialog>
            <v-btn
              v-if="canList"
              color="primary"
              dark
              class="ml-2 mb-2"
              @click="handleGetAgendamentos"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>

          <carga-descarga-list :headers="headersComputed" ref="listagem" />

          <div v-if="!canList" align="center">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

import CargaDescargaList from '@/components/extracao/CargaDescarga/CargaDescargaList.vue'
import CargaDescargaListFiltro from '@/components/extracao/CargaDescarga/CargaDescargaListFiltro.vue'

import IndicadoresGerencimentoServico from '@/components/patio/GerenciamentoServico/IndicadoresGerencimentoServico.vue'

import { data } from './_data'

const HEADERS_EXTRACAO_CARGA_DESCARGA_LOCALSTORAGE =
  'headersExtracaoCargaDescarga'

export default {
  name: 'ExtracaoCargaDescarga',
  components: {
    CargaDescargaList,
    CargaDescargaListFiltro,
    IndicadoresGerencimentoServico,
  },
  data,
  computed: {
    ...mapState('auth', ['empresaAtual']),

    headersComputed() {
      return this.headers.filter((header) => !!header.habilited)
    },

    permission() {
      return Sequences.CargaDescarga.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  watch: {
    headersComputed() {
      localStorage.setItem(
        HEADERS_EXTRACAO_CARGA_DESCARGA_LOCALSTORAGE,
        JSON.stringify(this.headers)
      )
    },
  },
  methods: {
    async handleGetAgendamentos() {
      const { listagem } = this.$refs
      await listagem.handleGetAgendamentos()
    },

    setIndicadores() {
      const headersSaved = localStorage.getItem(
        HEADERS_EXTRACAO_CARGA_DESCARGA_LOCALSTORAGE
      )

      const headersSavedParsed = JSON.parse(headersSaved)

      if (
        headersSaved &&
        Array.isArray(headersSavedParsed) &&
        headersSavedParsed.length > 0
      ) {
        const add = this.headers
          .map((header) => {
            if (
              !headersSavedParsed.some(
                (headerAlreadySaved) =>
                  headerAlreadySaved.value === header.value
              )
            )
              return header
          })
          .filter((header) => !!header)

        const continua = headersSavedParsed
          .map((headerAlreadySaved) => {
            if (
              this.headers.some(
                (header) => header.value === headerAlreadySaved.value
              )
            )
              return headerAlreadySaved
          })
          .filter((headerAlreadySaved) => !!headerAlreadySaved)

        this.headers = [...add, ...continua]
        localStorage.setItem(
          HEADERS_EXTRACAO_CARGA_DESCARGA_LOCALSTORAGE,
          JSON.stringify([...add, ...continua])
        )
      } else {
        localStorage.setItem(
          HEADERS_EXTRACAO_CARGA_DESCARGA_LOCALSTORAGE,
          JSON.stringify(this.headers)
        )
      }
    },
    closeDialogIndicadores() {
      this.dialogIndicadores = false
    },
  },
  created() {
    if (this.canList) {
      this.setIndicadores()
    }
  },
}
</script>
