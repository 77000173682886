export function data() {
  return {
    settingFilters: false,
    dependents: {
      fornecedor: ["produto", "sub_produto", "cliente", "transportadora"]
    },
    intervalo_base: (1000 * 60 * 60 * 24),
    intervalo_dias: 15,
    gates: [],
    params: {
      data_inicio: {
        value: '',
        visible: false,
      },
      data_fim: {
        value: '',
        visible: false,
      },
      gate: {
        value: '',
        visible: false,
        // get: () => { },
      },
      proprietario: {
        value: '',
        visible: false,
        // get: () => { },
      },
      fornecedor: {
        value: '',
        visible: false,
        get: this.getFornecedores,
      },
      produto: {
        value: '',
        visible: false,
      },
      sub_produto: {
        value: '',
        visible: false,
      },
      cliente: {
        value: '',
        visible: false,
        get: this.getClientes,
      },
      transportadora: {
        value: '',
        visible: false,
        get: this.getTransportadoras,
      },
      terminal: {
        value: '',
        visible: false,
        get: this.getContratos,
      },
      regiao: {
        value: '',
        visible: false,
        get: this.getRegioes,
      },
      representante: {
        value: '',
        visible: false,
        get: this.getRepresentantes,
      },
      motorista: {
        value: '',
        visible: false,
        get: this.getMotoristas,
      },
      placa_cavalo: {
        value: '',
        visible: false,
      },
      eixo: {
        value: '',
        visible: false,
      },
    },
  }
}
