<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <carga-descarga-list-concluidos
      :headers="headers"
      :tela="permission"
      ref="concluidos"
      @loading="loading = true"
      @loaded="loading = false"
    />
  </div>
</template>

<script>
import CargaDescargaListConcluidos from './CargaDescargaListConcluidos.vue'

import { Sequences } from '@/utils/permissions'

export default {
  name: 'CargaDescargaList',
  props: {
    headers: {
      type: Array,
    },
  },
  components: {
    CargaDescargaListConcluidos,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    permission() {
      return Sequences.ExtracaoCargaDescarga.toString()
    },
  },
  methods: {
    async handleGetAgendamentos() {
      await this.$refs.concluidos.handleGetAgendamentos()
    },
  },
}
</script>
