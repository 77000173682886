<template>
  <base-modal
    formTitle="Filtros"
    class="modal--indicadores-gerenciamento-servico"
  >
    <template v-slot:header>
      <div class="col-12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <div class="d-flex justify-space-between">
        <v-btn color="primary" plain small @click="clear()"> Limpar </v-btn>
        <v-btn color="primary" plain small @click="fill()">
          Selecionar Todos
        </v-btn>
      </div>
    </template>
    <template v-slot:body class="123">
      <div class="col-12">
        <draggable
          :list="headersComputed"
          handle=".handle"
          :disabled="search.length > 0"
        >
          <div
            v-for="(header, index) in headersComputed"
            :key="index"
            class="d-flex flex-row"
          >
            <v-checkbox
              v-model="header.habilited"
              :label="header.text"
              hide-details
            >
              <template v-slot:prepend>
                <span class="mt-1">
                  <i class="fa fa-align-justify handle"></i>
                </span>
              </template>
            </v-checkbox>
          </div>
        </draggable>
      </div>
    </template>
    <template v-slot:buttons>
      <v-btn
        color="primary"
        dark
        type="button"
        class="button-submit-green"
        @click="clearLocalStorage()"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn type="button" class="button-submit-green" @click="$emit('close')"
        >Salvar</v-btn
      >
    </template>
  </base-modal>
</template>
<script>
import BaseModal from '@/components/BaseModal/BaseModal.vue'
import draggable from 'vuedraggable'
import { latim_to_str } from '@/utils/string'

export default {
  props: {
    headers: {
      type: Array,
    },
    localStorageKey: {
      type: String,
      default: 'headersGerenciamentoServico',
    },
  },
  created() {
    this.list = this.headersComputed
  },
  data() {
    return {
      search: '',
      list: [],
    }
  },
  watch: {
    list(value) {
      this.$emit('save', value)
    },
  },
  computed: {
    headersComputed() {
      let headers = this.headers // .filter((item) => !item.block)
      if (this.search) {
        let search = latim_to_str(this.search)
        headers = headers.filter((item) => {
          return latim_to_str(item.text).includes(search)
        })
      }
      return headers
    },
  },
  methods: {
    clearLocalStorage() {
      this.list = this.headersComputed
      localStorage.removeItem(this.localStorageKey)
    },
    clear() {
      this.headersComputed.forEach((element) => {
        element.habilited = false
      })
    },
    fill() {
      this.headersComputed.forEach((element) => {
        element.habilited = true
      })
    },
  },
  components: { BaseModal, draggable },
  name: 'FiltrosGerencimentoServico',
}
</script>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
.modal--indicadores-gerenciamento-servico .v-card__text {
  padding-top: 0 !important;

  .container {
    padding-top: 0 !important;

    > div {
      padding-top: 6px !important;
    }
  }
}
</style>
