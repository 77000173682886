import { mapActions, mapMutations } from 'vuex'

import { cnpj, cpf } from 'cpf-cnpj-validator'

import { StatusAgendamento } from '@/utils/agendamento'

export const methods = {
  ...mapActions('template', [
    'successMessage',
    'errorMessage',
    'setDialogMessage',
  ]),
  ...mapActions('fornecedores', ['getFornecedores']),
  ...mapActions('clientes', ['getClientes']),
  ...mapActions('transportadoras', ['getTransportadoras']),
  ...mapActions('contratos', ['getContratos']),
  ...mapActions('patio', ['getRegioes', 'getRepresentantes']),
  ...mapActions('motoristas', ['getMotoristas']),
  ...mapMutations('extracaoCargaDescarga', ['UPDATE_PARAMS']),

  handleFixParams(fixedParams, [key, param]) {
    if (param.value) {
      const paramsWithCNPJ = [
        'fornecedor',
        'terminal',
        'cliente',
        'transportadora',
      ]
      const paramsWithCPF = ['representante', 'motorista']

      if (paramsWithCNPJ.includes(key))
        fixedParams[key] = cnpj.strip(param.value)
      else if (paramsWithCPF.includes(key))
        fixedParams[key] = cpf.strip(param.value)
      else fixedParams[key] = param.value
    }

    return fixedParams
  },
  fixParams() {
    return Object.entries(this.params).reduce(
      this.handleFixParams,
      new Object()
    )
  },
  string2date(string) {
    const dateParts = string.split("/");
    if (dateParts.length != 3) return
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject
  },
  search() {
    if (!this.areSettingsEmpty) {
      const fixedParams = {
        ...this.fixParams(),
        status: StatusAgendamento.CONCLUIDO,
      }
      if (fixedParams.data_inicio && fixedParams.data_fim) {
        const data_inicio = this.string2date(fixedParams.data_inicio)
        const data_fim = this.string2date(fixedParams.data_fim)
        if (
          data_fim - data_inicio >
          this.intervalo_base * this.intervalo_dias
        ) {
          const data_fim = new Date(
            data_inicio.valueOf() + this.intervalo_base * this.intervalo_dias
          )
          fixedParams.data_fim = this.date2BRstring(data_fim)
          this.successMessage({
            Aviso:
              'Data de e até alteradas para prevalecer o intervalo de ' +
              this.intervalo_dias +
              ' dias.',
            'Data de': fixedParams.data_inicio,
            'Data até': fixedParams.data_fim,
          })
        }
      } else {
        if (fixedParams.data_inicio) {
          const data_inicio = this.string2date(fixedParams.data_inicio)
          const data_fim = new Date(
            data_inicio.valueOf() + this.intervalo_base * this.intervalo_dias
          )
          fixedParams.data_fim = this.date2BRstring(data_fim)
          this.successMessage({
            Aviso:
              'Data de e até alteradas para prevalecer o intervalo de ' +
              this.intervalo_dias +
              ' dias.',
            'Data de': fixedParams.data_inicio,
            'Data até': fixedParams.data_fim,
          })
        } else {
          if (fixedParams.data_fim) {
            const data_fim = this.string2date(fixedParams.data_fim)
            const data_inicio = new Date(
              data_fim.valueOf() - this.intervalo_base * this.intervalo_dias
            )
            fixedParams.data_inicio = this.date2BRstring(data_inicio)
            this.successMessage({
              Aviso:
                'Data de e até alteradas para prevalecer o intervalo de ' +
                this.intervalo_dias +
                ' dias.',
              'Data de': fixedParams.data_inicio,
              'Data até': fixedParams.data_fim,
            })
          }
        }
      }
      this.UPDATE_PARAMS(fixedParams)
      this.$emit('close')
    }
  },

  async showOnlySelectedOptionsAndGetItems() {
    this.selectedOptions.forEach((option) => {
      this.params[option].visible = true
    })
    this.showDaddy()
  },

  showDaddy() {
    for (let [daddy, dependents] of Object.entries(this.dependents)) {
      const isSomeDependentVisible = dependents.some(
        (dependent) => this.params[dependent].visible
      )
      if (isSomeDependentVisible) {
        this.params[daddy].visible = true
      }
    }
  },
}
